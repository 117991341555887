<template>
  <div class="interna flyers">
    <HeaderNav />
    <PageTitle titulo="Cartazes &amp; Flyers" />
    <GridCartazes />
  </div>
</template>

<script>
import HeaderNav from "@/components/HeaderNav";
import PageTitle from "@/components/PageTitle";
import GridCartazes from "@/components/GridCartazes";
export default {
  components: {
    HeaderNav,
    PageTitle,
    GridCartazes
  }
};
</script>

<style lang="scss">
.flyers {
  padding-top: 4.4rem;
  padding-bottom: 2rem;
  @media screen and (min-width: 1025px) {
    padding-top: 0;
  }
}
</style>
