<template>
  <transition name="fade" appear>
    <div class="modal modal-fx-fadeInScale is-active">
      <div class="modal-background" @click="$emit('close')"></div>
      <div class="modal-content">
        <div class="image image-responsive">
          <slot name="modal-image" />
        </div>
      </div>
      <button
        @click="$emit('close')"
        class="modal-close is-large"
        aria-label="close"
      ></button>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    zoom: {
      type: Array
    }
  }
};
</script>

<style lang="scss">
.modal {
  display: block important !important;
}
.modal .modal-background {
  background-color: rgba(255, 255, 2555, 0.95) !important;
}

.delete::after,
.delete::before,
.modal-close::after,
.modal-close::before {
  background-color: #000 !important;
}

.is-large.delete,
.is-large.modal-close {
  height: 60px;
  max-height: 60px;
  max-width: 60px;
  min-height: 60px;
  min-width: 60px;
  width: 60px;
  border-radius: 0;
}

.delete::before,
.modal-close::before {
  height: 4px;
}

.delete::after,
.modal-close::after {
  width: 4px;
}

@media screen and (max-width: 768px) {
  .modal-close {
    top: 0;
    right: 0;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s linear;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
