import RadioSamba from "../../views/RecordCovers/RadioSamba.vue";
import ParaisoMiragem from "../../views/RecordCovers/ParaisoMiragem.vue";
import Subirusdoistiozin from "../../views/RecordCovers/Subirusdoistiozin.vue";
import Lsp from "../../views/RecordCovers/Lsp.vue";
import BlackAlien from "../../views/RecordCovers/BlackAlien.vue";
import Sabotage from "../../views/RecordCovers/Sabotage.vue";
import DuasDeCinco from "../../views/RecordCovers/DuasDeCinco.vue";
import NoNaOrelha from "../../views/RecordCovers/NoNaOrelha.vue";
import JahVan from "../../views/RecordCovers/JahVan.vue";
import CapimGuine from "../../views/RecordCovers/CapimGuine.vue";
import Abayomy from "../../views/RecordCovers/Abayomy.vue";
import MiltonCriolo from "../../views/RecordCovers/MiltonCriolo.vue";
import BembaTrio from "../../views/RecordCovers/BembaTrio.vue";
import Jahfa from "../../views/RecordCovers/Jahfa.vue";
import Manifesta from "../../views/RecordCovers/Manifesta.vue";
import Otto from "../../views/RecordCovers/Otto.vue";
import Angola from "../../views/RecordCovers/Angola.vue";
import CrioloEmicida from "../../views/RecordCovers/CrioloEmicida.vue";
import MarinaPeralta from "../../views/RecordCovers/MarinaPeralta.vue";
import Sonorado from "../../views/RecordCovers/Sonorado.vue";
import NZeSamantha from "../../views/RecordCovers/NZeSamantha.vue";
import BrunoE from "../../views/RecordCovers/BrunoE.vue";
//import NZYoungGods from "../../views/RecordCovers/NZYoungGods.vue";
import RockersControl from "../../views/RecordCovers/RockersControl.vue";
import Osas from "../../views/RecordCovers/Osas.vue";
import LurdezDaLuz from "../../views/RecordCovers/LurdezDaLuz.vue";
import Mamelo from "../../views/RecordCovers/Mamelo.vue";
import JamLab from "../../views/RecordCovers/JamLab.vue";
import RappinHood from "../../views/RecordCovers/RappinHood.vue";
// import Dubstereo from "../../views/RecordCovers/Dubstereo.vue";
import Paraquedas from "../../views/RecordCovers/Paraquedas.vue";
import Alagbe from "../../views/RecordCovers/Alagbe.vue";
import Collaboritmos from "../../views/RecordCovers/Collaboritmos.vue";
import Spfw from "../../views/RecordCovers/Spfw.vue";
import Mlnz from "../../views/RecordCovers/Mlnz.vue";
import OroSo from "../../views/RecordCovers/OroSo.vue";
import ModoAleatorio from "../../views/RecordCovers/ModoAleatorio.vue";
import DrDrumah from "../../views/RecordCovers/DrDrumah.vue";

const capas = [
  {
    path: "/en/record-covers/radio-samba",
    name: "Radio Samba EN",
    component: RadioSamba,
    meta: {
      title: "Rádio S.Amb.A. | Ricardo H Fernandes"
    }
  },
  {
    path: "/en/record-covers/paraiso-da-miragem",
    name: "Paraíso da Miragem EN",
    component: ParaisoMiragem,
    meta: {
      title: "Paraíso da Miragem | Ricardo H Fernandes"
    }
  },
  {
    path: "/en/record-covers/subirusdoistiozin",
    name: "Subirusdoistiozin EN",
    component: Subirusdoistiozin,
    meta: {
      title: "Subirusdoistiozin | Ricardo H Fernandes"
    }
  },
  {
    path: "/en/record-covers/lsp",
    name: "Lsp EN",
    component: Lsp,
    meta: {
      title: "Los Sebosos Postizos | Ricardo H Fernandes"
    }
  },
  {
    path: "/en/record-covers/black-alien",
    name: "black-alien EN",
    component: BlackAlien,
    meta: {
      title: "Black Alien | Ricardo H Fernandes"
    }
  },
  {
    path: "/en/record-covers/sabotage",
    name: "sabotage EN",
    component: Sabotage,
    meta: {
      title: "Sabotage | Ricardo H Fernandes"
    }
  },
  {
    path: "/en/record-covers/duas-de-cinco",
    name: "duas-de-cinco EN",
    component: DuasDeCinco,
    meta: {
      title: "Duas de Cinco | Ricardo H Fernandes"
    }
  },
  {
    path: "/en/record-covers/no-na-orelha",
    name: "no-na-orelha EN",
    component: NoNaOrelha,
    meta: {
      title: "Nó na Orelha | Ricardo H Fernandes"
    }
  },
  {
    path: "/en/record-covers/jah-van",
    name: "jah-van EN",
    component: JahVan,
    meta: {
      title: "Jah-Van | Ricardo H Fernandes"
    }
  },
  {
    path: "/en/record-covers/capim-guine",
    name: "capim-guine EN",
    component: CapimGuine,
    meta: {
      title: "Capim Guiné | Ricardo H Fernandes"
    }
  },
  {
    path: "/en/record-covers/abayomy",
    name: "abayomy EN",
    component: Abayomy,
    meta: {
      title: "Abayomy feat. Orlando Julius | Ricardo H Fernandes"
    }
  },
  {
    path: "/en/record-covers/milton-e-criolo",
    name: "milton-e-criolo EN",
    component: MiltonCriolo,
    meta: {
      title: "Milton Nascimento e Criolo | Ricardo H Fernandes"
    }
  },
  {
    path: "/en/record-covers/bemba-trio",
    name: "bemba-trio EN",
    component: BembaTrio,
    meta: {
      title: "Bemba Trio | Ricardo H Fernandes"
    }
  },
  {
    path: "/en/record-covers/jahfa",
    name: "jahfa EN",
    component: Jahfa,
    meta: {
      title: "Jahzz | Ricardo H Fernandes"
    }
  },
  {
    path: "/en/record-covers/manifesta",
    name: "manifesta EN",
    component: Manifesta,
    meta: {
      title: "Manifesta | Ricardo H Fernandes"
    }
  },
  {
    path: "/en/record-covers/otto",
    name: "otto EN",
    component: Otto,
    meta: {
      title: "Changez Tout | Ricardo H Fernandes"
    }
  },
  {
    path: "/en/record-covers/angola",
    name: "angola EN",
    component: Angola,
    meta: {
      title: "Angola e Bahia | Ricardo H Fernandes"
    }
  },
  {
    path: "/en/record-covers/criolo-e-emicida",
    name: "criolo-e-emicida EN",
    component: CrioloEmicida,
    meta: {
      title: "Criolo & Emicida | Ricardo H Fernandes"
    }
  },
  {
    path: "/en/record-covers/marina-peralta",
    name: "marina-peralta EN",
    component: MarinaPeralta,
    meta: {
      title: "Marina Peralta | Ricardo H Fernandes"
    }
  },
  {
    path: "/en/record-covers/sonorado",
    name: "sonorado EN",
    component: Sonorado,
    meta: {
      title: "Sonorado | Ricardo H Fernandes"
    }
  },
  {
    path: "/en/record-covers/nz-e-samantha",
    name: "nz-e-samantha EN",
    component: NZeSamantha,
    meta: {
      title: "Nação Zumbi e Samantha Schmütz | Ricardo H Fernandes"
    }
  },
  {
    path: "/en/record-covers/bruno-e",
    name: "bruno-e EN",
    component: BrunoE,
    meta: {
      title: "Bruno E. & Coletivo SuperJazz | Ricardo H Fernandes"
    }
  },
  // {
  //   path: "/en/record-covers/nz-young-gods",
  //   name: "nz-young-gods EN",
  //   component: NZYoungGods,
  //   meta: {
  //     title: "Nação Zumbi & The Young Gods | Ricardo H Fernandes"
  //   }
  // },
  {
    path: "/en/record-covers/rockers-control",
    name: "rockers-control EN",
    component: RockersControl,
    meta: {
      title: "Rockers Control | Ricardo H Fernandes"
    }
  },
  {
    path: "/en/record-covers/osas",
    name: "osas EN",
    component: Osas,
    meta: {
      title: "Osas Destiny | Ricardo H Fernandes"
    }
  },
  {
    path: "/en/record-covers/lurdez-da-luz",
    name: "lurdez-da-luz EN",
    component: LurdezDaLuz,
    meta: {
      title: "Lurdez da Luz | Ricardo H Fernandes"
    }
  },
  {
    path: "/en/record-covers/mamelo",
    name: "mamelo EN",
    component: Mamelo,
    meta: {
      title: "Mamelo Sound System | Ricardo H Fernandes"
    }
  },
  {
    path: "/en/record-covers/jam-lab",
    name: "jam-lab EN",
    component: JamLab,
    meta: {
      title: "Jam Lab / Victor Rice | Ricardo H Fernandes"
    }
  },
  {
    path: "/en/record-covers/rappin-hood",
    name: "rappin-hood EN",
    component: RappinHood,
    meta: {
      title: "Rappin' Hood | Ricardo H Fernandes"
    }
  },
  // {
  //   path: "/en/record-covers/dubstereo",
  //   name: "dubstereo EN",
  //   component: Dubstereo,
  //   meta: {
  //     title: "Dubstereo | Ricardo H Fernandes"
  //   }
  // },
  {
    path: "/en/record-covers/paraquedas",
    name: "paraquedas EN",
    component: Paraquedas,
    meta: {
      title: "Paraquedas | Ricardo H Fernandes"
    }
  },
  {
    path: "/en/record-covers/alagbe",
    name: "alagbe EN",
    component: Alagbe,
    meta: {
      title: "Alagbe | Ricardo H Fernandes"
    }
  },
  {
    path: "/en/record-covers/collaboritmos",
    name: "collaboritmos EN",
    component: Collaboritmos,
    meta: {
      title: "Collaboritmos | Ricardo H Fernandes"
    }
  },
  {
    path: "/en/record-covers/spfw",
    name: "spfw EN",
    component: Spfw,
    meta: {
      title: "SPFW | Ricardo H Fernandes"
    }
  },
  {
    path: "/en/record-covers/mlnz",
    name: "mlnz EN",
    component: Mlnz,
    meta: {
      title: "MLNZ | Ricardo H Fernandes"
    }
  },
  {
    path: "/en/record-covers/oro-so",
    name: "oro-so EN",
    component: OroSo,
    meta: {
      title: "Oro Só | Ricardo H Fernandes"
    }
  },
  {
    path: "/en/record-covers/modo-aleatorio",
    name: "modo-aleatorio EN",
    component: ModoAleatorio,
    meta: {
      title: "Modo Aleatório | Ricardo H Fernandes"
    }
  },
  {
    path: "/en/record-covers/dr-drumah",
    name: "dr-drumah EN",
    component: DrDrumah,
    meta: {
      title: "Dr. Drumah | Ricardo H Fernandes"
    }
  }
];

export default capas;
