<template>
  <div>
    <header class="header pt-5 mb-5">
      <div class="level">
        <div class="logo level-item is-justify-content-flex-start">
          <a href="/">
            <img src="../assets/logo.png" alt="Ricardo H Fernandes" />
          </a>
        </div>
        <div class="level-item is-justify-content-flex-end">
          <div class="lang-switcher">
            <a href="/" class="lang-pt" title="Versão em português">PT</a>
            <a href="/en/" class="lang-en" title="English version">EN</a>
          </div>
          <div id="nav-icon" class="nav-icon" @click="showMenu = !showMenu">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </div>
    </header>

    <header class="header-mobile">
      <div class="logo-mobile is-inline-flex is-justify-content-flex-start">
        <a href="/">
          <img src="../assets/logo-mobile.png" alt="Ricardo H Fernandes" />
        </a>
      </div>
      <div class="nav-icon">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </header>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showMenu: false
    };
  }
};
</script>

<style>
@media screen and (max-width: 1025px) {
  .header {
    display: none;
  }
}

.header-mobile {
  width: 100%;
  background-color: #fff;
  height: 70px;
  box-shadow: rgba(0, 0, 0, 0.2) 0 0 10px;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
  z-index: 10;
}

@media screen and (min-width: 768px) {
  .header-mobile {
    height: 80px;
  }
}

@media screen and (min-width: 1025px) {
  .header-mobile {
    display: none;
  }
}

.logo,
.logo img {
  width: auto;
}

.logo a {
  display: flex;
}

.logo img {
  max-width: 250px;
  margin-left: 1rem;
  transition: opacity 0.2s ease-in-out;
}

.logo:hover img {
  opacity: 0.85;
  transition: opacity 0.2s ease-in-out;
}

.logo-mobile {
  margin-top: 0.5rem;
  margin-left: -0.5rem;
}
.logo-mobile img {
  max-width: 100%;
}

@media screen and (min-width: 769px) {
  .logo,
  .logo img {
    width: 650px;
    max-width: 100%;
  }
  .logo img {
    margin-left: -1.5rem;
  }
  .logo-mobile {
    margin-left: -0.75rem;
  }
}

@media screen and (min-width: 1024px) {
  .logo,
  .logo img {
    max-height: 75px;
  }
}

/* @media screen and (min-width: 1024px) {
  .lang-switcher {
    display: none;
  }             CONTINUAR AQUI: EXIBICAO DO MENU LANG SWITCHER
} */

.lang-switcher a {
  width: 84px;
  height: 42px;
  background: #ccc;
  display: inline-block;
  text-indent: -9999rem;
  margin-left: 1.5rem;
  transition: opacity 0.2s ease-in-out;
  position: relative;
  z-index: 0;
}

.lang-switcher a:hover {
  opacity: 0.7;
  transition: opacity 0.2s ease-in-out;
}

.lang-switcher a:before,
.lang-switcher a:after {
  content: "";
  width: 42px;
  height: 42px;
  position: absolute;
}

.lang-switcher a:before {
  left: 0;
}
.lang-switcher a:after {
  right: 0;
}

.lang-switcher a.lang-pt:before {
  background-color: #508c68;
}
.lang-switcher a.lang-pt:after {
  background-color: #f9d950;
}
.lang-switcher a.lang-en:before {
  background-color: #ea413e;
}
.lang-switcher a.lang-en:after {
  background-color: #3391ab;
}

.header-mobile .lang-switcher {
  display: none;
}

.home .header-mobile .lang-switcher {
  display: flex;
}

@media screen and (max-width: 360px) {
  .logo-mobile {
    max-width: 240px;
  }
  .header-mobile .lang-switcher a {
    margin-left: 0.75rem;
  }
}

@media screen and (max-width: 767px) {
  .logo-mobile {
    max-width: 250px;
  }
}

@media screen and (max-width: 321px) {
  .logo-mobile {
    max-width: 230px;
  }
}

@media screen and (min-width: 768px) {
  .logo-mobile {
    max-width: 350px;
  }
}

@media screen and (max-width: 767px) {
  .header-mobile .lang-switcher a {
    width: 48px;
    height: 24px;
  }

  .header-mobile .lang-switcher a:before,
  .header-mobile .lang-switcher a:after {
    width: 24px;
    height: 24px;
  }
}

/* NAV ICON */

.home .nav-icon {
  display: none;
}

.nav-icon {
  width: 32px;
  height: 32px;
  position: relative;
  margin: 0 0.25rem 0 0.5rem;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
}

@media screen and (min-width: 1025px) {
  .nav-icon {
    width: 42px;
    height: 42px;
    margin: 0 0 0 1.5rem;
  }
}

.nav-icon span {
  display: block;
  position: absolute;
  height: 5px;
  width: 100%;
  background: #000;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}

@media screen and (min-width: 1025px) {
  .nav-icon span {
    height: 7px;
  }
}

.nav-icon span:nth-child(1) {
  top: 0;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

.nav-icon span:nth-child(2) {
  top: 12px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

@media screen and (min-width: 1025px) {
  .nav-icon span:nth-child(2) {
    top: 17px;
  }
}

.nav-icon span:nth-child(3) {
  top: 25px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

@media screen and (min-width: 1025px) {
  .nav-icon span:nth-child(3) {
    top: 35px;
  }
}

.nav-icon.active span:nth-child(1) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  top: 2px;
  left: 4px;
}

@media screen and (min-width: 1025px) {
  .nav-icon.active span:nth-child(1) {
    left: 6px;
  }
}

.nav-icon.active span:nth-child(2) {
  width: 0%;
  opacity: 0;
}

.nav-icon.active span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  top: 25px;
  left: 4px;
}

@media screen and (min-width: 1025px) {
  .nav-icon.active span:nth-child(3) {
    top: 32px;
    left: 6px;
  }
}
</style>
