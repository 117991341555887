<template>
  <section class="page-title">
    <div class="mt-4 mb-4">
      <h2 class="is-uppercase is-size-1 has-text-weight-bold is-size-4-touch">
        <a href="javascript:history.back()" title="Voltar">
          <img
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAtElEQVRYR+3WIQrDQBCF4T+6PqeoqqqLyKVjeoaaip6hOioqrAgsLZTMzGPHbPTC+3iTgRlI/obkfDqgN6BsYAQWYAPuZ39uFaCEP4Ar8ARuLQF1+AuYgU8rQCi8ICMjCIdHAJJwL0AW7gFIw60AebgVsAIX4A1MllX7t5KWLUgHpI+gNClHWEZwjFKK8ACkTXgBMkQEIEFEAWGEAvCNaH6Q1NuRdpKdPYB+3qlG0AG9AXcDO9hlOCHakvpGAAAAAElFTkSuQmCC"
          />
        </a>
        <a href="javascript:history.back()" title="Voltar">
          {{ titulo }}
        </a>
      </h2>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    titulo: {
      type: String,
      required: true
    }
  }
};
</script>

<style lang="scss">
.page-title {
  h2 {
    display: inline-grid;
    grid-template-rows: 1fr;
    grid-template-columns: auto 1fr;
    gap: 0.5rem;
    line-height: 1.2;
    font-weight: bold;
    text-transform: uppercase;
    align-items: center;
    a {
      display: flex;
    }
  }
}
</style>
