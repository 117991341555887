<template>
  <div class="header-404">
    <header class="header pt-5 mb-5">
      <div class="level">
        <div class="logo level-item is-justify-content-flex-start">
          <a href="/">
            <img src="../assets/logo.png" alt="Ricardo H Fernandes" />
          </a>
        </div>
      </div>
    </header>

    <header class="header-mobile">
      <div class="logo-mobile is-inline-flex is-justify-content-flex-start">
        <a href="/">
          <img src="../assets/logo-mobile.png" alt="Ricardo H Fernandes" />
        </a>
      </div>
    </header>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showMenu: false
    };
  }
};
</script>

<style lang="scss" scoped>
@media screen and (max-width: 1025px) {
  .header {
    display: none;
  }
}

.header-mobile {
  width: 100%;
  background-color: #fff;
  height: 70px;
  box-shadow: rgba(0, 0, 0, 0.2) 0 0 10px;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
  z-index: 10;
}

@media screen and (min-width: 768px) {
  .header-mobile {
    height: 80px;
  }
}

@media screen and (min-width: 1025px) {
  .header-mobile {
    display: none;
  }
}

.logo,
.logo img {
  width: auto;
}

.logo a {
  display: flex;
}

.logo img {
  max-width: 250px;
  margin-left: 1rem;
  transition: opacity 0.2s ease-in-out;
}

.logo:hover img {
  opacity: 0.85;
  transition: opacity 0.2s ease-in-out;
}

.logo-mobile {
  margin-top: 0.5rem;
  margin-left: -0.5rem;
}
.logo-mobile img {
  max-width: 100%;
}

@media screen and (min-width: 769px) {
  .logo,
  .logo img {
    width: 650px;
    max-width: 100%;
  }
  .logo img {
    margin-left: -1.5rem;
  }
  .logo-mobile {
    margin-left: -0.75rem;
  }
}

@media screen and (min-width: 1024px) {
  .logo,
  .logo img {
    max-height: 75px;
  }
}

@media screen and (max-width: 360px) {
  .logo-mobile {
    max-width: 240px;
  }
}

@media screen and (max-width: 767px) {
  .logo-mobile {
    max-width: 250px;
  }
}

@media screen and (max-width: 321px) {
  .logo-mobile {
    max-width: 230px;
  }
}

@media screen and (min-width: 768px) {
  .logo-mobile {
    max-width: 350px;
  }
}
</style>
