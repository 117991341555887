import RadioSamba from "../../views/Capas/RadioSamba.vue";
import ParaisoMiragem from "../../views/Capas/ParaisoMiragem.vue";
import Subirusdoistiozin from "../../views/Capas/Subirusdoistiozin.vue";
import Lsp from "../../views/Capas/Lsp.vue";
import BlackAlien from "../../views/Capas/BlackAlien.vue";
import Sabotage from "../../views/Capas/Sabotage.vue";
import DuasDeCinco from "../../views/Capas/DuasDeCinco.vue";
import NoNaOrelha from "../../views/Capas/NoNaOrelha.vue";
import JahVan from "../../views/Capas/JahVan.vue";
import CapimGuine from "../../views/Capas/CapimGuine.vue";
import Abayomy from "../../views/Capas/Abayomy.vue";
import MiltonCriolo from "../../views/Capas/MiltonCriolo.vue";
import BembaTrio from "../../views/Capas/BembaTrio.vue";
import Jahfa from "../../views/Capas/Jahfa.vue";
import Manifesta from "../../views/Capas/Manifesta.vue";
import Otto from "../../views/Capas/Otto.vue";
import Angola from "../../views/Capas/Angola.vue";
import CrioloEmicida from "../../views/Capas/CrioloEmicida.vue";
import MarinaPeralta from "../../views/Capas/MarinaPeralta.vue";
import Sonorado from "../../views/Capas/Sonorado.vue";
import NZeSamantha from "../../views/Capas/NZeSamantha.vue";
import BrunoE from "../../views/Capas/BrunoE.vue";
//import NZYoungGods from "../../views/Capas/NZYoungGods.vue";
import RockersControl from "../../views/Capas/RockersControl.vue";
import Osas from "../../views/Capas/Osas.vue";
import LurdezDaLuz from "../../views/Capas/LurdezDaLuz.vue";
import Mamelo from "../../views/Capas/Mamelo.vue";
import JamLab from "../../views/Capas/JamLab.vue";
import RappinHood from "../../views/Capas/RappinHood.vue";
// import Dubstereo from "../../views/Capas/Dubstereo.vue";
import Paraquedas from "../../views/Capas/Paraquedas.vue";
import Alagbe from "../../views/Capas/Alagbe.vue";
import Collaboritmos from "../../views/Capas/Collaboritmos.vue";
import Spfw from "../../views/Capas/Spfw.vue";
import Mlnz from "../../views/Capas/Mlnz.vue";
import OroSo from "../../views/Capas/OroSo.vue";
import ModoAleatorio from "../../views/Capas/ModoAleatorio.vue";
import DrDrumah from "../../views/Capas/DrDrumah.vue";

const capas = [
  {
    path: "/capas-de-discos/radio-samba",
    name: "Radio Samba",
    component: RadioSamba,
    meta: {
      title: "Rádio S.Amb.A. | Ricardo H Fernandes"
    }
  },
  {
    path: "/capas-de-discos/paraiso-da-miragem",
    name: "Paraíso da Miragem",
    component: ParaisoMiragem,
    meta: {
      title: "Paraíso da Miragem | Ricardo H Fernandes"
    }
  },
  {
    path: "/capas-de-discos/subirusdoistiozin",
    name: "Subirusdoistiozin",
    component: Subirusdoistiozin,
    meta: {
      title: "Subirusdoistiozin | Ricardo H Fernandes"
    }
  },
  {
    path: "/capas-de-discos/lsp",
    name: "Lsp",
    component: Lsp,
    meta: {
      title: "Los Sebosos Postizos | Ricardo H Fernandes"
    }
  },
  {
    path: "/capas-de-discos/black-alien",
    name: "black-alien",
    component: BlackAlien,
    meta: {
      title: "Black Alien | Ricardo H Fernandes"
    }
  },
  {
    path: "/capas-de-discos/sabotage",
    name: "sabotage",
    component: Sabotage,
    meta: {
      title: "Sabotage | Ricardo H Fernandes"
    }
  },
  {
    path: "/capas-de-discos/duas-de-cinco",
    name: "duas-de-cinco",
    component: DuasDeCinco,
    meta: {
      title: "Duas de Cinco | Ricardo H Fernandes"
    }
  },
  {
    path: "/capas-de-discos/no-na-orelha",
    name: "no-na-orelha",
    component: NoNaOrelha,
    meta: {
      title: "Nó na Orelha | Ricardo H Fernandes"
    }
  },
  {
    path: "/capas-de-discos/jah-van",
    name: "jah-van",
    component: JahVan,
    meta: {
      title: "Jah-Van | Ricardo H Fernandes"
    }
  },
  {
    path: "/capas-de-discos/capim-guine",
    name: "capim-guine",
    component: CapimGuine,
    meta: {
      title: "Capim Guiné | Ricardo H Fernandes"
    }
  },
  {
    path: "/capas-de-discos/abayomy",
    name: "abayomy",
    component: Abayomy,
    meta: {
      title: "Abayomy feat. Orlando Julius | Ricardo H Fernandes"
    }
  },
  {
    path: "/capas-de-discos/milton-e-criolo",
    name: "milton-e-criolo",
    component: MiltonCriolo,
    meta: {
      title: "Milton Nascimento e Criolo | Ricardo H Fernandes"
    }
  },
  {
    path: "/capas-de-discos/bemba-trio",
    name: "bemba-trio",
    component: BembaTrio,
    meta: {
      title: "Bemba Trio | Ricardo H Fernandes"
    }
  },
  {
    path: "/capas-de-discos/jahfa",
    name: "jahfa",
    component: Jahfa,
    meta: {
      title: "Jahzz | Ricardo H Fernandes"
    }
  },
  {
    path: "/capas-de-discos/manifesta",
    name: "manifesta",
    component: Manifesta,
    meta: {
      title: "Manifesta | Ricardo H Fernandes"
    }
  },
  {
    path: "/capas-de-discos/otto",
    name: "otto",
    component: Otto,
    meta: {
      title: "Changez Tout | Ricardo H Fernandes"
    }
  },
  {
    path: "/capas-de-discos/angola",
    name: "angola",
    component: Angola,
    meta: {
      title: "Angola e Bahia | Ricardo H Fernandes"
    }
  },
  {
    path: "/capas-de-discos/criolo-e-emicida",
    name: "criolo-e-emicida",
    component: CrioloEmicida,
    meta: {
      title: "Criolo & Emicida | Ricardo H Fernandes"
    }
  },
  {
    path: "/capas-de-discos/marina-peralta",
    name: "marina-peralta",
    component: MarinaPeralta,
    meta: {
      title: "Marina Peralta | Ricardo H Fernandes"
    }
  },
  {
    path: "/capas-de-discos/sonorado",
    name: "sonorado",
    component: Sonorado,
    meta: {
      title: "Sonorado | Ricardo H Fernandes"
    }
  },
  {
    path: "/capas-de-discos/nz-e-samantha",
    name: "nz-e-samantha",
    component: NZeSamantha,
    meta: {
      title: "Nação Zumbi e Samantha Schmütz | Ricardo H Fernandes"
    }
  },
  {
    path: "/capas-de-discos/bruno-e",
    name: "bruno-e",
    component: BrunoE,
    meta: {
      title: "Bruno E. & Coletivo SuperJazz | Ricardo H Fernandes"
    }
  },
  // {
  //   path: "/capas-de-discos/nz-young-gods",
  //   name: "nz-young-gods",
  //   component: NZYoungGods,
  //   meta: {
  //     title: "Nação Zumbi & The Young Gods | Ricardo H Fernandes"
  //   }
  // },
  {
    path: "/capas-de-discos/rockers-control",
    name: "rockers-control",
    component: RockersControl,
    meta: {
      title: "Rockers Control | Ricardo H Fernandes"
    }
  },
  {
    path: "/capas-de-discos/osas",
    name: "osas",
    component: Osas,
    meta: {
      title: "Osas Destiny | Ricardo H Fernandes"
    }
  },
  {
    path: "/capas-de-discos/lurdez-da-luz",
    name: "lurdez-da-luz",
    component: LurdezDaLuz,
    meta: {
      title: "Lurdez da Luz | Ricardo H Fernandes"
    }
  },
  {
    path: "/capas-de-discos/mamelo",
    name: "mamelo",
    component: Mamelo,
    meta: {
      title: "Mamelo Sound System | Ricardo H Fernandes"
    }
  },
  {
    path: "/capas-de-discos/jam-lab",
    name: "jam-lab",
    component: JamLab,
    meta: {
      title: "Jam Lab / Victor Rice | Ricardo H Fernandes"
    }
  },
  {
    path: "/capas-de-discos/rappin-hood",
    name: "rappin-hood",
    component: RappinHood,
    meta: {
      title: "Rappin' Hood | Ricardo H Fernandes"
    }
  },
  // {
  //   path: "/capas-de-discos/dubstereo",
  //   name: "dubstereo",
  //   component: Dubstereo,
  //   meta: {
  //     title: "Dubstereo | Ricardo H Fernandes"
  //   }
  // },
  {
    path: "/capas-de-discos/paraquedas",
    name: "paraquedas",
    component: Paraquedas,
    meta: {
      title: "Paraquedas | Ricardo H Fernandes"
    }
  },
  {
    path: "/capas-de-discos/alagbe",
    name: "alagbe",
    component: Alagbe,
    meta: {
      title: "Alagbe | Ricardo H Fernandes"
    }
  },
  {
    path: "/capas-de-discos/collaboritmos",
    name: "collaboritmos",
    component: Collaboritmos,
    meta: {
      title: "Collaboritmos | Ricardo H Fernandes"
    }
  },
  {
    path: "/capas-de-discos/spfw",
    name: "spfw",
    component: Spfw,
    meta: {
      title: "SPFW | Ricardo H Fernandes"
    }
  },
  {
    path: "/capas-de-discos/mlnz",
    name: "mlnz",
    component: Mlnz,
    meta: {
      title: "MLNZ | Ricardo H Fernandes"
    }
  },
  {
    path: "/capas-de-discos/oro-so",
    name: "oro-so",
    component: OroSo,
    meta: {
      title: "Oro Só | Ricardo H Fernandes"
    }
  },
  {
    path: "/capas-de-discos/modo-aleatorio",
    name: "modo-aleatorio",
    component: ModoAleatorio,
    meta: {
      title: "Modo Aleatório | Ricardo H Fernandes"
    }
  },
  {
    path: "/capas-de-discos/dr-drumah",
    name: "dr-drumah",
    component: DrDrumah,
    meta: {
      title: "Dr. Drumah | Ricardo H Fernandes"
    }
  }
];

export default capas;
