<template>
  <div class="interna contato">
    <HeaderNav />
    <PageTitle titulo="Contato" />
    <form class="form" action="https://formspree.io/f/myylzpqk" method="POST">
      <p
        class="pb-5 is-size-5 is-size-6-touch is-uppercase has-text-weight-medium no-letter-spacing"
      >
        Utilize o formulário abaixo para entrar em contato.
      </p>
      <div class="field mb-5 mt-2">
        <label class="label is-size-5 is-size-6-touch is-uppercase">
          Nome <small class="no-letter-spacing">(obrigatório)</small>
        </label>
        <div class="control">
          <input
            class="input is-medium"
            type="text"
            name="name"
            v-model="name"
            required
          />
        </div>
      </div>

      <div class="field mb-5">
        <label class="label is-size-5 is-size-6-touch is-uppercase">
          E-mail <small class="no-letter-spacing">(obrigatório)</small>
        </label>
        <div class="control">
          <input
            class="input is-medium"
            type="email"
            name="_replyto"
            v-model="email"
            required
          />
        </div>
      </div>

      <div class="field mb-5">
        <label class="label is-size-5 is-size-6-touch is-uppercase">
          Mensagem <small class="no-letter-spacing">(obrigatório)</small>
        </label>
        <div class="control">
          <textarea
            rows="2"
            class="textarea is-medium"
            name="message"
            v-model="message"
            required
          ></textarea>
        </div>
      </div>

      <div class="field">
        <p class="control">
          <button class="button is-black is-uppercase" type="submit">
            Enviar
          </button>
        </p>
      </div>
    </form>
  </div>
</template>

<script>
import HeaderNav from "@/components/HeaderNav";
import PageTitle from "@/components/PageTitle";
export default {
  components: {
    HeaderNav,
    PageTitle
  },
  data() {
    return {
      name: "",
      email: "",
      message: ""
    };
  }
};
</script>

<style lang="scss">
.contato {
  padding-top: 4.4rem;
  @media screen and (min-width: 1025px) {
    padding-top: 0;
  }
  .form {
    max-width: 600px;
    margin: auto;
    @media screen and (min-width: 768px) {
      margin: 0;
    }
    .field {
      small {
        color: #aaa;
        margin-left: 0.5rem;
        font-weight: lighter;
      }
      .button,
      .input,
      .textarea {
        border-radius: 0;
        z-index: 0;
      }
      .button {
        font-weight: bold;
        padding: 1.5rem 2rem;
        transition: opacity 0.2s ease-in-out;
        &:hover {
          opacity: 0.85;
          transition: opacity 0.2s ease-in-out;
        }
      }
    }
  }
}
</style>
