<template>
  <div class="interna capas">
    <Loader v-if="isLoading" />
    <HeaderNav />
    <PageTitle titulo="Capas de Discos" />
    <GridCapas />
  </div>
</template>

<script>
import HeaderNav from "@/components/HeaderNav";
import PageTitle from "@/components/PageTitle";
import GridCapas from "@/components/GridCapas";
export default {
  components: {
    HeaderNav,
    PageTitle,
    GridCapas
  }
};
</script>

<style lang="scss">
.capas {
  padding-top: 4.4rem;
  padding-bottom: 2rem;
  @media screen and (min-width: 1025px) {
    padding-top: 0;
  }
}
</style>
