import Duloco from "../../views/Miscellaneous/Duloco.vue";
import TikTak from "../../views/Miscellaneous/TikTak.vue";
import Salvador from "../../views/Miscellaneous/Salvador.vue";
import Minduca from "../../views/Miscellaneous/Minduca.vue";
import Elena from "../../views/Miscellaneous/Elena.vue";
import PatuaDiscos from "../../views/Miscellaneous/PatuaDiscos.vue";
import Insolacao from "../../views/Miscellaneous/Insolacao.vue";
import OlharInstigado from "../../views/Miscellaneous/OlharInstigado.vue";
import SoundSystem from "../../views/Miscellaneous/SoundSystem.vue";
import Yemanja from "../../views/Miscellaneous/Yemanja.vue";
import Yoani from "../../views/Miscellaneous/Yoani.vue";
import IstambulAgora from "../../views/Miscellaneous/IstambulAgora.vue";

const miscellaneous = [
  {
    path: "/en/miscellaneous/duloco",
    name: "en/duloco",
    component: Duloco,
    meta: {
      title: "Uma década duloco | Ricardo H Fernandes"
    }
  },
  {
    path: "/en/miscellaneous/tik-tak",
    name: "en/tik-tak",
    component: TikTak,
    meta: {
      title: "Tik Tak | Ricardo H Fernandes"
    }
  },
  {
    path: "/en/miscellaneous/salvador-graffiti",
    name: "en/salvador-graffiti",
    component: Salvador,
    meta: {
      title: "Salvador Graffiti | Ricardo H Fernandes"
    }
  },
  {
    path: "/en/miscellaneous/minduca",
    name: "en/minduca",
    component: Minduca,
    meta: {
      title: "Minduca | Ricardo H Fernandes"
    }
  },
  {
    path: "/en/miscellaneous/elena",
    name: "en/elena",
    component: Elena,
    meta: {
      title: "Elena | Ricardo H Fernandes"
    }
  },
  {
    path: "/en/miscellaneous/patua-discos",
    name: "en/patua-discos",
    component: PatuaDiscos,
    meta: {
      title: "Patuá Discos | Ricardo H Fernandes"
    }
  },
  {
    path: "/en/miscellaneous/insolacao",
    name: "en/insolacao",
    component: Insolacao,
    meta: {
      title: "Insolação | Ricardo H Fernandes"
    }
  },
  {
    path: "/en/miscellaneous/olhar-instigado",
    name: "en/olhar-instigado",
    component: OlharInstigado,
    meta: {
      title: "Olhar Instigado | Ricardo H Fernandes"
    }
  },
  {
    path: "/en/miscellaneous/sound-system",
    name: "en/sound-system",
    component: SoundSystem,
    meta: {
      title: "Sound System | Ricardo H Fernandes"
    }
  },
  {
    path: "/en/miscellaneous/yemanja",
    name: "en/yemanja",
    component: Yemanja,
    meta: {
      title: "Festa de Yemanjá | Ricardo H Fernandes"
    }
  },
  {
    path: "/en/miscellaneous/yoani",
    name: "en/yoani",
    component: Yoani,
    meta: {
      title: "A viagem de Yoani | Ricardo H Fernandes"
    }
  },
  {
    path: "/en/miscellaneous/istambul-agora",
    name: "en/istambul-agora",
    component: IstambulAgora,
    meta: {
      title: "Istambul Agora | Ricardo H Fernandes"
    }
  }
];

export default miscellaneous;
