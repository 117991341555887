<template>
  <div>
    <Header404 />
    <div class="erro-404 interna no-letter-spacing">
      <h1 class="is-size-1 has-text-weight-bold">404</h1>
      <p class="mb-5 is-size-3 is-size-6-touch is-uppercase">
        <small>Página não encontrada</small>
        <small>Page not found</small>
      </p>
      <router-link to="/" class="button is-size-6 is-black is-uppercase">
        Home
      </router-link>
    </div>
  </div>
</template>

<script>
import Header404 from "@/components/Header404";
export default {
  components: { Header404 }
};
</script>

<style lang="scss" scoped>
.erro-404 {
  font-size: 3rem;
  padding-top: 8rem;
  max-width: 600px;
  small {
    display: block;
    margin: 0.5rem 0;
    &:last-child {
      color: #999;
    }
  }

  .button {
    font-weight: bold;
    padding: 1.5rem 2rem;
    transition: opacity 0.2s ease-in-out;
    border-radius: 0;
    &:hover {
      opacity: 0.85;
      transition: opacity 0.2s ease-in-out;
    }
  }
}
</style>
