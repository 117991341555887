<template>
  <div class="interna flyers">
    <HeaderNavEnglish />
    <PageTitle titulo="Posters &amp; Flyers" />
    <GridPosters />
  </div>
</template>

<script>
import HeaderNavEnglish from "@/components/HeaderNavEnglish";
import PageTitle from "@/components/PageTitle";
import GridPosters from "@/components/GridPosters";
export default {
  components: {
    HeaderNavEnglish,
    PageTitle,
    GridPosters
  }
};
</script>
