import Vue from "vue";
import App from "./App.vue";
import router from "./router";
// import Axios from "axios";
import "../node_modules/bulma/css/bulma.min.css";
import "@/assets/css/main.scss";
import titleMixin from "@/mixins/titleMixin";

Vue.config.productionTip = false;
Vue.mixin(titleMixin);
// Vue.prototype.$http = Axios;

new Vue({
  router,
  render: h => h(App)
}).$mount("#app");
