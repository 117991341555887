<template>
  <div>
    <Loader v-if="isLoading" />
    <section class="grid-home mt-5">
      <figure v-for="(item, i) in items" :key="`item${i}`">
        <a @click="showModal(item.href)" :title="item.title">
          <img :src="item.src" :alt="item.title" />
        </a>
      </figure>
    </section>
    <Modal
      v-if="isModalShow"
      @click.stop="selectedImage = null"
      @close="isModalShow = false"
    >
      <img slot="modal-image" :src="selectedImage" />
    </Modal>
  </div>
</template>

<script>
import axios from "axios";
import Loader from "@/components/Loader";
import Modal from "@/components/Modal";
export default {
  components: { Modal, Loader },
  data() {
    return {
      items: null,
      isModalShow: false,
      selectedImage: null,
      isLoading: false
    };
  },
  methods: {
    showModal(url) {
      this.isModalShow = true;
      this.selectedImage = url;
    }
  },
  async created() {
    this.isLoading = true;
    try {
      await axios
        .get("../../../api/home.json")
        .then(response => (this.items = response.data));
      setTimeout(() => (this.isLoading = false), 1000);
    } catch (error) {
      console.log(error);
      this.isLoading = false;
    }
  }
};
</script>

<style lang="scss">
.grid-home {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 1rem;
  @media screen and (min-width: 768px) {
    grid-template-columns: repeat(8, 1fr);
    gap: 0.5rem;
  }
  @media screen and (min-width: 800px) {
    grid-template-columns: repeat(12, 1fr);
    gap: 0.5rem;
  }
  @media screen and (min-width: 1200px) {
    grid-template-columns: repeat(16, 1fr);
    gap: 0.5rem;
  }
  figure {
    margin-bottom: -0.5rem;
    cursor: pointer;
    opacity: 1;
    transition: opacity 0.2s ease-in-out;
    &:hover {
      transition: opacity 0.2s ease-in-out;
      opacity: 0.5;
    }
  }
}
</style>
