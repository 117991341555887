<template>
  <section class="grid-details">
    <div>
      <div class="columns is-desktop">
        <div class="column is-half is-12-touch">
          <div class="infos-mobile mb-5">
            <p>
              <strong class="is-block has-text-weight-bold">
                {{ item.artist }}
              </strong>
              <span class="is-block">{{ item.titleEN }}, {{ item.year }}</span>
            </p>
          </div>
          <transition name="fade">
            <img
              v-if="showExtras"
              loading="lazy"
              :src="selectedImage"
              :alt="item.title"
            />
          </transition>
        </div>
        <div class="column is-half is-12-touch">
          <div
            class="extras-wrapper is-flex is-flex-direction-column is-align-content-space-around"
          >
            <ul class="extras">
              <li v-for="(item, i) in item.extras" :key="`item${i}`">
                <a
                  @click="
                    showExtras(item.zoom);
                    setActiveItemId(i);
                  "
                >
                  <img
                    loading="lazy"
                    :src="item.thumb"
                    :class="{ active: activeItemId === i }"
                  />
                </a>
              </li>
            </ul>
            <div class="infos">
              <p class="is-uppercase is-size-3">
                <strong class="is-block has-text-weight-bold">
                  {{ item.artist }}
                </strong>
                <span class="is-block">
                  {{ item.titleEN }}, {{ item.year }}
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true
    },
    extras: {
      type: Array,
      required: true
    },
    selectedImage: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      activeItemId: "",
      isActiveImage: false,
      isActiveThumb: false
    };
  },
  methods: {
    showExtras(url) {
      this.isModalShow = true;
      this.isActiveThumb = true;
      this.selectedImage = url;
    },
    setActiveItemId(i) {
      this.activeItemId = i;
    }
  },
  created() {
    this.activeItemId = 0;
  }
};
</script>

<style lang="scss">
@import url("../assets/css/grid-details.scss");
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s linear;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
