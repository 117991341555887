<template>
  <div>
    <nav class="nav nav-desktop" role="navigation" aria-label="main navigation">
      <ul>
        <li>
          <router-link to="/capas-de-discos/">Capas de Discos</router-link>
        </li>
        <li>
          <router-link to="/info/">Info</router-link>
        </li>
        <li>
          <router-link to="/cartazes-flyers/">
            Cartazes &amp; Flyers
          </router-link>
        </li>
        <li>
          <a href="https://www.instagram.com/rmagrao" target="_blank">
            Instagram
          </a>
        </li>
        <li>
          <router-link to="/diversos/">
            Diversos
          </router-link>
        </li>
        <li>
          <a href="https://www.twitter.com/rmagrao" target="_blank">Twitter</a>
        </li>
        <li>
          <a href="https://vimeo.com/ricardohfernandes" target="_blank">
            Cinema &amp; TV
          </a>
        </li>
        <li>
          <router-link to="/contato/">Contato</router-link>
        </li>
      </ul>
    </nav>
    <nav class="nav nav-mobile" role="navigation" aria-label="main navigation">
      <ul>
        <li>
          <router-link to="/capas-de-discos/">Capas de Discos</router-link>
        </li>
        <li class="lang-switcher">
          <router-link to="/" class="lang-pt" title="Versão em português">
            PT
          </router-link>
          <router-link to="/en/" class="lang-en" title="English version">
            EN
          </router-link>
        </li>
        <li>
          <router-link to="/cartazes-flyers/">
            Cartazes &amp; Flyers
          </router-link>
        </li>
        <li><router-link to="/info/">Info</router-link></li>
        <li><router-link to="/diversos/">Diversos</router-link></li>
        <li>
          <a href="https://www.instagram.com/rmagrao" target="_blank">
            Instagram
          </a>
        </li>
        <li>
          <a href="https://vimeo.com/ricardohfernandes" target="_blank">
            Cinema &amp; TV
          </a>
        </li>
        <li>
          <router-link to="/contato/">Contato</router-link>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
@import url("../assets/css/nav.scss");
.nav-mobile {
  display: block;
}
.nav-desktop {
  display: none;
}

.nav-mobile .lang-switcher a {
  width: 36px;
  height: 18px;
  margin-left: 0.75rem;
}

.nav-mobile .lang-switcher a:before,
.nav-mobile .lang-switcher a:after {
  width: 18px;
  height: 18px;
}

@media screen and (min-width: 1024px) {
  .nav-mobile {
    display: none;
  }
  .nav-desktop {
    display: block;
  }
}
</style>
