import Home from "../views/Home.vue";
import HomeEnglish from "../views/HomeEnglish.vue";
import About from "../views/About.vue";
import Info from "../views/Info.vue";
import Capas from "../views/Capas.vue";
import RecordCovers from "../views/RecordCovers.vue";
import Contato from "../views/Contato.vue";
import Contact from "../views/Contact.vue";
import Diversos from "../views/Diversos.vue";
import Miscellaneous from "../views/Miscellaneous.vue";
import CartazesFlyers from "../views/CartazesFlyers.vue";
import PostersFlyers from "../views/PostersFlyers.vue";
import PageNotFound from "../views/404.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      title: "Home | Ricardo H Fernandes"
    }
  },
  {
    path: "*",
    name: "PageNotFound",
    component: PageNotFound,
    meta: {
      title: "404 | Ricardo H Fernandes"
    }
  },
  {
    path: "/en",
    name: "HomeEnglish",
    component: HomeEnglish,
    meta: {
      title: "Home | Ricardo H Fernandes"
    }
  },
  {
    path: "/en/about",
    name: "About",
    component: About,
    meta: {
      title: "About | Ricardo H Fernandes"
    }
  },
  {
    path: "/info",
    name: "Info",
    component: Info,
    meta: {
      title: "Info | Ricardo H Fernandes"
    }
  },
  {
    path: "/capas-de-discos",
    name: "Capas",
    component: Capas,
    meta: {
      title: "Capas de Discos | Ricardo H Fernandes"
    }
  },
  {
    path: "/en/record-covers",
    name: "RecordCovers",
    component: RecordCovers,
    meta: {
      title: "Record Covers | Ricardo H Fernandes"
    }
  },
  {
    path: "/contato",
    name: "Contato",
    component: Contato,
    meta: {
      title: "Contato | Ricardo H Fernandes"
    }
  },
  {
    path: "/en/contact",
    name: "Contact",
    component: Contact,
    meta: {
      title: "Contact | Ricardo H Fernandes"
    }
  },
  {
    path: "/diversos",
    name: "Diversos",
    component: Diversos,
    meta: {
      title: "Diversos | Ricardo H Fernandes"
    }
  },
  {
    path: "/en/miscellaneous",
    name: "Miscellaneous",
    component: Miscellaneous,
    meta: {
      title: "Miscellaneous | Ricardo H Fernandes"
    }
  },
  {
    path: "/cartazes-flyers",
    name: "CartazesFlyers",
    component: CartazesFlyers,
    meta: {
      title: "Cartazes & Flyers | Ricardo H Fernandes"
    }
  },
  {
    path: "/en/posters-flyers",
    name: "PostersFlyers",
    component: PostersFlyers,
    meta: {
      title: "Posters & Flyers | Ricardo H Fernandes"
    }
  }
];

export default routes;
