<template>
  <div class="interna contato">
    <HeaderNavEnglish />
    <PageTitle titulo="Contact" />
    <form class="form" action="https://formspree.io/f/myylzpqk" method="POST">
      <p
        class="pb-5 is-size-5 is-size-6-touch is-uppercase has-text-weight-medium no-letter-spacing"
      >
        Use the form below to send a message.
      </p>
      <div class="field mb-5 mt-2">
        <label class="label is-size-5 is-size-6-touch is-uppercase">
          Name <small class="no-letter-spacing">(required)</small>
        </label>
        <div class="control">
          <input
            class="input is-medium"
            type="text"
            name="name"
            v-model="name"
            required
          />
        </div>
      </div>

      <div class="field mb-5">
        <label class="label is-size-5 is-size-6-touch is-uppercase">
          E-mail <small class="no-letter-spacing">(required)</small>
        </label>
        <div class="control">
          <input
            class="input is-medium"
            type="email"
            name="_replyto"
            v-model="email"
            required
          />
        </div>
      </div>

      <div class="field mb-5">
        <label class="label is-size-5 is-size-6-touch is-uppercase">
          Message <small class="no-letter-spacing">(required)</small>
        </label>
        <div class="control">
          <textarea
            rows="2"
            class="textarea is-medium"
            name="message"
            v-model="message"
            required
          ></textarea>
        </div>
      </div>

      <div class="field">
        <p class="control">
          <button class="button is-black is-uppercase" type="submit">
            Send
          </button>
        </p>
      </div>
    </form>
  </div>
</template>

<script>
import HeaderNavEnglish from "@/components/HeaderNavEnglish";
import PageTitle from "@/components/PageTitle";
export default {
  components: {
    HeaderNavEnglish,
    PageTitle
  },
  data() {
    return {
      name: "",
      email: "",
      message: ""
    };
  }
};
</script>
