<template>
  <div class="interna capas">
    <HeaderNavEnglish />
    <PageTitle titulo="Record Covers" />
    <GridCovers />
  </div>
</template>

<script>
import HeaderNavEnglish from "@/components/HeaderNavEnglish";
import PageTitle from "@/components/PageTitle";
import GridCovers from "@/components/GridCovers";
export default {
  components: {
    HeaderNavEnglish,
    PageTitle,
    GridCovers
  }
};
</script>
