<template>
  <div class="home-english">
    <Header />
    <NavHomeEnglish />
    <GridHome />
  </div>
</template>

<script>
import Header from "@/components/Header";
import NavHomeEnglish from "@/components/NavHomeEnglish";
import GridHome from "@/components/GridHome";
export default {
  components: {
    Header,
    NavHomeEnglish,
    GridHome
  }
};
</script>

<style lang="scss">
.home-english {
  padding-top: 5.5rem;
  padding-bottom: 3rem;
  @media screen and (min-width: 768px) {
    padding-top: 6.35rem;
  }
  @media screen and (min-width: 1025px) {
    padding-top: 0;
    .nav-icon {
      display: none;
    }
  }
}
</style>
