<template>
  <div>
    <Loader v-if="isLoading" />
    <section class="grid-diversos">
      <figure v-for="capa in capas" :key="capa.id">
        <router-link :to="capa.url" :title="capa.title">
          <img :src="capa.src" :alt="capa.title" />
        </router-link>
      </figure>
    </section>
  </div>
</template>

<script>
import Loader from "@/components/Loader";
import axios from "axios";
export default {
  components: { Loader },
  data() {
    return {
      capas: null,
      isLoading: false
    };
  },
  async created() {
    this.isLoading = true;
    try {
      await axios
        .get("../../api/diversos.json")
        .then(response => (this.capas = response.data));
      setTimeout(() => (this.isLoading = false), 1000);
    } catch (error) {
      console.log(error);
      this.isLoading = false;
    }
  }
};
</script>

<style lang="scss">
.grid-diversos {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  gap: 1rem;
  @media screen and (min-width: 768px) {
    grid-template-columns: repeat(6, 1fr);
  }
  @media screen and (min-width: 1024px) {
    margin-top: 3rem;
    gap: 3rem;
  }
  figure {
    margin-bottom: -0.5rem;
    cursor: pointer;
    opacity: 1;
    transition: opacity 0.2s ease-in-out;
    &:hover {
      transition: opacity 0.2s ease-in-out;
      opacity: 0.5;
    }
  }
}
</style>
