<template>
  <section class="grid-masonry">
    <Loader v-if="isLoading" />
    <figure v-for="(flyer, i) in flyers" :key="`flyer${i}`">
      <a @click="showModal(flyer.zoom)" :title="flyer.title">
        <img :src="flyer.src" :alt="flyer.title" />
      </a>
    </figure>
    <Modal
      v-if="isModalShow"
      @click.stop="selectedImage = null"
      @close="isModalShow = false"
    >
      <img slot="modal-image" :src="selectedImage" />
    </Modal>
  </section>
</template>

<script>
import axios from "axios";
import Loader from "@/components/Loader";
import Modal from "@/components/Modal.vue";
export default {
  components: { Modal, Loader },
  data() {
    return {
      flyers: null,
      isModalShow: false,
      selectedImage: null,
      isLoading: false
    };
  },
  methods: {
    showModal(url) {
      this.isModalShow = true;
      this.selectedImage = url;
    }
  },
  async created() {
    this.isLoading = true;
    try {
      await axios
        .get("../../../api/cartazes-flyers.json")
        .then(response => (this.flyers = response.data));
      setTimeout(() => (this.isLoading = false), 2000);
    } catch (error) {
      console.log(error);
      this.isLoading = false;
    }
  }
};
</script>

<style lang="scss">
.grid-masonry {
  -moz-column-count: 3;
  column-count: 3;
  -moz-column-gap: 1rem;
  column-gap: 1rem;
  -moz-column-fill: balance;
  column-fill: balance;
  padding-bottom: 3rem;
  @media screen and (min-width: 768px) {
    -moz-column-count: 6;
    column-count: 6;
  }
  @media screen and (min-width: 1200px) {
    -moz-column-count: 10;
    column-count: 10;
  }
  figure {
    margin-bottom: 0.5rem;
    cursor: pointer;
    opacity: 1;
    transition: opacity 0.2s ease-in-out;
    break-inside: avoid;
    page-break-inside: avoid;
    &:hover {
      transition: opacity 0.2s ease-in-out;
      opacity: 0.5;
    }
  }
}
</style>
