<template>
  <div class="interna diversos">
    <HeaderNavEnglish />
    <PageTitle titulo="Miscellaneous" />
    <GridMiscellaneous />
  </div>
</template>

<script>
import HeaderNavEnglish from "@/components/HeaderNavEnglish";
import PageTitle from "@/components/PageTitle";
import GridMiscellaneous from "@/components/GridMiscellaneous";
export default {
  components: {
    HeaderNavEnglish,
    PageTitle,
    GridMiscellaneous
  }
};
</script>
