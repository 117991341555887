<template>
  <div class="interna capas">
    <Loader v-if="isLoading" />
    <HeaderNav />
    <PageTitle titulo="Capas de Discos" />
    <GalleryDetails
      v-for="(item, i) in items"
      :key="`item${i}`"
      :item="item"
      :selectedImage="selectedImage"
      :extras="item.extras"
      v-model="selectedImage"
    />
  </div>
</template>

<script>
import axios from "axios";
import HeaderNav from "@/components/HeaderNav";
import PageTitle from "@/components/PageTitle";
import GalleryDetails from "@/components/GalleryDetails";
import Loader from "@/components/Loader";
export default {
  components: {
    HeaderNav,
    PageTitle,
    GalleryDetails,
    Loader
  },
  data() {
    return {
      items: [],
      selectedImage: "../../../../img/capas/36-oro-so/thumb@2x.jpg",
      isLoading: false
    };
  },
  async created() {
    this.isLoading = true;
    try {
      await axios
        .get("../../../api/capas/rockers-control.json")
        .then(response => (this.items = response.data));
      setTimeout(() => (this.isLoading = false), 1000);
    } catch (error) {
      console.log(error);
      this.isLoading = false;
    }
  }
};
</script>
