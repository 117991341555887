<template>
  <div class="interna info">
    <HeaderNav />
    <PageTitle titulo="Info" />
    <section class="about-content">
      <div class="columns">
        <div class="column is-3 is-12-mobile">
          <img
            loading="lazy"
            src="@/assets/ricardo.jpg"
            alt="Foto de Ricardo H Fernandes"
          />
        </div>
        <div class="column is-9 is-12-mobile">
          <p class="is-size-3 is-size-5-touch pb-5 is-uppercase">
            Nascido em São Paulo.
          </p>
          <p class="is-size-3 is-size-5-touch pb-5 is-uppercase">
            Formado em Comunicação pela Escola Superior de Propaganda e
            Marketing em 1992, foi sócio dos estúdios de artes gráficas Estação
            Manda, Galactiae Studios e Cinética Design.
          </p>
          <p class="is-size-3 is-size-5-touch pb-5 is-uppercase">
            Desde 1993 vem desenvolvendo inúmeros projetos na área de criação
            gráfica, tendo produzido peças como capas de discos e livros,
            cartazes e identidades visuais comerciais. Tem também larga
            experiência na área audio-visual, seja na criação de vinhetas para
            programas de televisão, ou projeções para espetáculos teatrais e
            videoclipes.
          </p>
          <p class="is-size-3 is-size-5-touch pb-5 is-uppercase">
            Foi premiado pela criação da melhor capa de disco na Bienal de
            Design Gráfico de São Paulo, em 2003.
          </p>
          <p class="is-size-3 is-size-5-touch pb-5 is-uppercase">
            Foi diretor de arte dos filmes documentários "Tropicália" (prêmio de
            melhor direção de arte no festival UNASUR – Argentina, 2011), "Shoot
            Yourself", "Cidade Cinza", "A Viagem de Yoani”, “Surfar É Coisa de
            Rico”, “Vilanova Artigas” e outros, além de ter dirigido vários
            video-clipes e filmes publicitários.
          </p>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import HeaderNav from "@/components/HeaderNav";
import PageTitle from "@/components/PageTitle";
export default {
  components: {
    HeaderNav,
    PageTitle
  }
};
</script>
<style lang="scss">
.info {
  padding-top: 4.4rem;
  @media screen and (min-width: 1025px) {
    padding-top: 0;
  }
  .about-content {
    p {
      letter-spacing: initial;
      line-height: 1.1;
      @media screen and (min-width: 1025px) {
        line-height: 1.2;
      }
    }
  }
}
</style>
