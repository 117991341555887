<template>
  <div>
    <div class="loader-overlay">
      <div class="lds-dual-ring"></div>
    </div>
    <div class="overlay-blur"></div>
  </div>
</template>
<script>
export default {
  name: "loader"
};
</script>

<style lang="scss">
.overlay-blur {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.95);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  // filter: blur(1.5rem);
  // transition: all 0.75s cubic-bezier(.71,0,.33,1.56) 0ms;
}
.loader-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;

  .lds-dual-ring {
    display: inline-block;
    width: 80px;
    height: 80px;
    position: relative;
    z-index: 9999;
  }
  .lds-dual-ring:after {
    content: " ";
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid #333;
    border-color: #333 transparent #333 transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }
  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
</style>
