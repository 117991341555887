<template>
  <section class="grid-masonry">
    <Loader v-if="isLoading" />
    <figure v-for="(flyer, i) in flyers" :key="`flyer${i}`">
      <a @click="showModal(flyer.zoom)" :title="flyer.title">
        <img :src="flyer.src" :alt="flyer.title" />
      </a>
    </figure>
    <Modal
      v-if="isModalShow"
      @click.stop="selectedImage = null"
      @close="isModalShow = false"
    >
      <img slot="modal-image" :src="selectedImage" />
    </Modal>
  </section>
</template>

<script>
import axios from "axios";
import Loader from "@/components/Loader.vue";
import Modal from "@/components/Modal.vue";
export default {
  components: { Modal, Loader },
  data() {
    return {
      flyers: null,
      isModalShow: false,
      selectedImage: null,
      isLoading: false
    };
  },
  methods: {
    showModal(url) {
      this.isModalShow = true;
      this.selectedImage = url;
    }
  },
  async created() {
    this.isLoading = true;
    try {
      await axios
        .get("../../../api/posters-flyers.json")
        .then(response => (this.flyers = response.data));
      setTimeout(() => (this.isLoading = false), 2000);
    } catch (error) {
      console.log(error);
      this.isLoading = false;
    }
  }
};
</script>
