<template>
  <div class="interna info">
    <HeaderNavEnglish />
    <PageTitle titulo="About" />
    <section class="about-content">
      <div class="columns">
        <div class="column is-3 is-12-mobile">
          <img
            loading="lazy"
            src="@/assets/ricardo.jpg"
            alt="Foto de Ricardo H Fernandes"
          />
        </div>
        <div class="column is-9 is-12-mobile">
          <p class="is-size-3 is-size-5-touch pb-5 is-uppercase">
            Born in São Paulo.
          </p>
          <p class="is-size-3 is-size-5-touch pb-5 is-uppercase">
            Graduated in Communications from Escola Superior de Propaganda &amp;
            Marketing in 1992, he was an affiliate of the graphic studios
            Estação Manda, Galactiae Studios and Cinetica Design.
          </p>
          <p class="is-size-3 is-size-5-touch pb-5 is-uppercase">
            Since 1993 he has been producing numerous projects in the field of
            graphic design such as album and book covers, posters and commercial
            visual identities as well as an extensive domain in the audio-visual
            field, be it in the creation of vignettes for TV shows or
            projections for theatrical shows and video clips.
          </p>
          <p class="is-size-3 is-size-5-touch pb-5 is-uppercase">
            Awarded for Best Creation of the best album cover at the 2003 Bienal
            of Graphic Design in São Paulo.
          </p>
          <p class="is-size-3 is-size-5-touch pb-5 is-uppercase">
            Art Director of the documentary films "Tropicália" (Award for Best
            Art Direction at the UNASUR Festival - Argentina, 2011), "Shoot
            Yourself", "Cidade Cinza", "A Viagem de Yoani", "Surfar É Coisa de
            Rico", "Vilanova Artigas" and others, as well as having directed
            numerous video clips and advertising films.
          </p>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import HeaderNavEnglish from "@/components/HeaderNavEnglish";
import PageTitle from "@/components/PageTitle";
export default {
  components: {
    HeaderNavEnglish,
    PageTitle
  }
};
</script>
